import { createRouter, createWebHistory } from 'vue-router';
import axios from 'axios';
import { useStore } from '../store/main';

const routes = [
	{
		path: '/',
		component: () => import(/* webpackChunkName: "hybrid-sheep" */ '../views/product/hybrid-sheep.vue'),
	},

	{
		path: '/privacy',
		component: () => import(/* webpackChunkName: "privacy" */ '../views/privacy.vue'),
	},

	{
		path: '/support',
		component: () => import(/* webpackChunkName: "support" */ '../views/support.vue'),
	},

	{
		path: '/checkout/form',
		component: () => import(/* webpackChunkName: "checkout" */ '../views/checkout/form.vue'),
	},

	{
		path: '/login',
		component: () => import(/* webpackChunkName: "site" */ '../views/login.vue'),
	},

	{
		path: '/setToken',
		component: () => import(/* webpackChunkName: "site" */ '../views/setToken.vue'),
	},

	{
		path: '/message',
		component: () => import(/* webpackChunkName: "message" */ '../views/message.vue'),
	},

	/*
	.##.....##..######..########.########.
	.##.....##.##....##.##.......##.....##
	.##.....##.##.......##.......##.....##
	.##.....##..######..######...########.
	.##.....##.......##.##.......##...##..
	.##.....##.##....##.##.......##....##.
	..#######...######..########.##.....##
	*/
	{
		path: '/user/index',
		component: () => import(/* webpackChunkName: "user" */ '../views/user/index.vue'),
		meta: {
			isLogin: true,
		},
	},

	{
		path: '/user/orders',
		component: () => import(/* webpackChunkName: "user" */ '../views/user/orders.vue'),
		meta: {
			isLogin: true,
		},
	},
	{
		path: '/user/order/item/:id(\\d+)',
		component: () => import(/* webpackChunkName: "user" */ '../views/user/orderItem.vue'),
		meta: {
			isLogin: true,
		},
	},
	{
		path: '/user/order/complete/:id(\\d+)',
		component: () => import(/* webpackChunkName: "user" */ '../views/user/orderComplete.vue'),
		meta: {
			isLogin: true,
		},
	},
	{
		path: '/user/order/fail/:id(\\d+)',
		component: () => import(/* webpackChunkName: "user" */ '../views/user/orderFail.vue'),
		meta: {
			isLogin: true,
		},
	},

	/*
	.########..########...#######..########..##.....##..######..########
	.##.....##.##.....##.##.....##.##.....##.##.....##.##....##....##...
	.##.....##.##.....##.##.....##.##.....##.##.....##.##..........##...
	.########..########..##.....##.##.....##.##.....##.##..........##...
	.##........##...##...##.....##.##.....##.##.....##.##..........##...
	.##........##....##..##.....##.##.....##.##.....##.##....##....##...
	.##........##.....##..#######..########...#######...######.....##...
	*/
	{
		path: '/product/listing',
		component: () => import(/* webpackChunkName: "product" */ '../views/product/listing.vue'),
	},

	{
		path: '/product/item/:id(\\d+)/:name?',
		component: () => import(/* webpackChunkName: "product" */ '../views/product/item.vue'),
	},

	{
		path: '/product/bedstead',
		component: () => import(/* webpackChunkName: "bedstead" */ '../views/product/bedstead.vue'),
	},

	// {
	// 	path: '/products/hybrid-sheep',
	// 	component: () => import(/* webpackChunkName: "hybrid-sheep" */ '../views/product/hybrid-sheep.vue'),
	// },

	{
		path: '/product/hybrid-sheep',
		component: () => import(/* webpackChunkName: "hybrid-sheep" */ '../views/product/hybrid-sheep.vue'),
	},

	{
		path: '/product/modular-sheep',
		component: () => import(/* webpackChunkName: "modular-sheep" */ '../views/product/modular-sheep.vue'),
	},

	{
		path: '/product/black-sheep',
		component: () => import(/* webpackChunkName: "black-sheep" */ '../views/product/black-sheep.vue'),
	},

	// {
	//   path: "/product/hybrid-sheep2",
	//   component: () =>
	//     import(
	//       /* webpackChunkName: "test" */ "../views/product/HybridSheep2.vue"
	//     ),
	// },

	{
		path: '/testing',
		component: () => import(/* webpackChunkName: "test" */ '../views/testing.vue'),
	},

	/*
	.##..........#####...##.......
	.##....##...##...##..##....##.
	.##....##..##.....##.##....##.
	.##....##..##.....##.##....##.
	.#########.##.....##.#########
	.......##...##...##........##.
	.......##....#####.........##.
	*/
	{
		path: '/:pathMatch(.*)*',
		component: () => import(/* webpackChunkName: "site" */ '../views/notFound.vue'),
	},
];

/*
.......##.......##.......##.......##.......##.......##.......##.......##.......##.......##.......##
......##.......##.......##.......##.......##.......##.......##.......##.......##.......##.......##.
.....##.......##.......##.......##.......##.......##.......##.......##.......##.......##.......##..
....##.......##.......##.......##.......##.......##.......##.......##.......##.......##.......##...
...##.......##.......##.......##.......##.......##.......##.......##.......##.......##.......##....
..##.......##.......##.......##.......##.......##.......##.......##.......##.......##.......##.....
.##.......##.......##.......##.......##.......##.......##.......##.......##.......##.......##......
*/

const router = createRouter({
	// history: createWebHistory(process.env.BASE_URL),
	// history: createWebHistory("/"),
	history: createWebHistory(process.env.ROUTER_BASE_URL),
	routes,
	scrollBehavior() {
		// always scroll to top
		return { top: 0 };
	},
});

async function checkUser() {
	if (!window.isStatusChecked) {
		const token = localStorage.getItem('token');
		if (token) {
			const config = {
				headers: {
					authorization: `Bearer ${token}`,
				},
			};

			try {
				const result = await axios.post(process.env.API_URL + '/login/checkUser', {}, config);

				const store = useStore();
				store.setUser(result.data.item);
			} catch (e) {
				// remove token
				localStorage.removeItem('token');
			}
		}
	}
	window.isStatusChecked = true;
}

async function initCart() {
	const cartJson = localStorage.getItem('cart') || '[]';

	const store = useStore();

	let json = [];
	try {
		json = JSON.parse(cartJson);
	} catch (e) {
		// yada
	}
	store.initCart(json);
}

async function initCheckout() {
	const checkoutJson = localStorage.getItem('checkout') || '[]';

	const store = useStore();

	let json = [];
	try {
		json = JSON.parse(checkoutJson);
	} catch (e) {
		// yada
	}
	store.initCheckout(json);
}

router.beforeEach(async (to, from, next) => {
	if (!window.isInitCart) {
		window.isInitCart = true;
		initCart();
		initCheckout();
	}

	// tracking
	try {
		window.fbq('track', 'PageView');
	} catch (e) {
		// yada
	}

	if (to.meta.isLogin) {
		// check user
		await checkUser();

		if (window.isLogin) {
			if (to.meta.isAdmin) {
				if (window.isAdmin) {
					next();
				} else {
					next('/login');
				}
			} else {
				next();
			}
		} else {
			next('/login');
		}
	} else {
		next();

		// check user
		checkUser();
	}
});

export default router;
