const items: Array<any> = [
	// { id: 1, name: "現金價．72 折", period: 0, percent: 0.72 },
	{ id: 1, name: '現金價', period: 0, percent: 1 },
	// { id: 2, name: "6 期．0 利率．再打 75 折", period: 6, percent: 0.75 },
	// { id: 3, name: "12 期．0 利率．再打 75 折", period: 12, percent: 0.75 },
	// { id: 4, name: "24 期．0 利率．再打 8 折", period: 24, percent: 0.8 },
	{ id: 2, name: '6 期', period: 6, percent: 0.95 },

	// 2024-03-01 kwan: 不需要了
	// { id: 3, name: '12 期', period: 12, percent: 0.95 },
	// { id: 4, name: '24 期', period: 24, percent: 0.9 },
];

export default items;
