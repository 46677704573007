import { defineStore } from 'pinia';

// import {
//   addToCart as gtagAddToCart,
//   removeFromCart as gtagRemoveFromCart,
// } from "../helper/tracking";

import tracking from '../helper/tracking';

interface CartItem {
	id: number;
	quantity: number;
	price: number;
}

interface NotificationItem {
	name: string;
	isActive: boolean;
}

const cart: CartItem[] = [];
const notifications: NotificationItem[] = [];

export const useStore = defineStore('Main', {
	state: () => ({
		user: {
			id: null,
			name: '',
			email: '',
			permissions: [],
			isAdmin: false,
			isSuperAdmin: false,
		},
		cart: cart,
		checkout: {
			couponCode: '',
			period: 0,
		},
		isStatusChecked: false,
		isLogin: false,
		isInvalidToken: false,
		notifications,
	}),

	getters: {},

	actions: {
		addNotification(q: string) {
			const temp: NotificationItem = { name: q, isActive: true };
			this.notifications.push(temp);
			const self = this;
			setTimeout(function () {
				temp.isActive = false;
				self.notifications.push({ name: '', isActive: false });
				self.notifications.pop();
			}, 2000);
		},

		setCheckout(period: number, couponCode: string) {
			this.checkout.period = period;
			this.checkout.couponCode = couponCode;

			const checkoutJson = JSON.stringify(this.checkout);
			localStorage.setItem('checkout', checkoutJson);
		},

		initCheckout(q: any) {
			this.checkout.couponCode = q.couponCode;
			this.checkout.period = q.period;
		},

		initCart(q: any) {
			this.cart = q;
		},

		clearCart() {
			this.cart = [];
			localStorage.setItem('cart', '[]');
		},

		resetCart(q: CartItem) {
			this.cart = [q];
			const cartJson = JSON.stringify(this.cart);

			localStorage.setItem('cart', cartJson);
		},

		addCart(q: CartItem) {
			const temp = this.cart.find((z) => z.id == q.id);
			if (!temp) {
				this.cart.push(q);

				tracking.addToCart(q.id, q.price);
				// window.gtag('event', 'add_to_cart', { items: [{ id }] });
			} else {
				temp.quantity = q.quantity;
			}

			const cartJson = JSON.stringify(this.cart);
			localStorage.setItem('cart', cartJson);
		},

		isInCart(q: number) {
			return this.cart.find((z) => z.id == q) != null;
		},

		updateQuantity(id: number, quantity: number) {
			const temp = this.cart.find((z) => z.id == id);
			if (temp) {
				temp.quantity = quantity;
			}
		},

		removeCart(q: number) {
			const item = this.cart[q];
			this.cart.splice(q, 1);
			const cartJson = JSON.stringify(this.cart);
			localStorage.setItem('cart', cartJson);

			tracking.removeFromCart(item.id);
			// window.gtag('event', 'remove_from_cart', {
			//   items: [{ id }],
			// });
		},

		setStatus() {
			this.isStatusChecked = true;
		},

		setUser(q: any) {
			this.user.id = q.id;
			this.user.email = q.email;
			this.user.name = q.name;
			this.user.isAdmin = q.isAdmin;
			this.user.isSuperAdmin = q.isSuperAdmin;
			this.user.permissions = q.permissions;
			this.isLogin = true;

			window.isLogin = true;
			window.isAdmin = q.isAdmin;
		},

		logout() {
			this.user.id = null;
			this.user.email = '';
			this.user.name = '';
			this.user.isAdmin = false;
			this.user.isSuperAdmin = false;
			this.user.permissions = [];
			this.isLogin = false;

			window.isLogin = false;
			localStorage.removeItem('token');

			this.addNotification('已登出');
		},
	},
});
