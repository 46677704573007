const items: Array<any> = [
	{
		id: true,
		name: 'Yes',
	},
	{
		id: false,
		name: 'No',
	},
];

export default items;
