const items: Array<any> = [
	{
		id: 1,
		name: '簡訊',
	},
	{
		id: 2,
		name: 'Email',
	},
];

export default items;
