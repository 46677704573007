<template>
	<metainfo>		
		<template v-slot:title="{ content }">{{
			content ? `${content} | Sheeper` : `Sheeper | 頂級專業床墊研發`
		}}</template>
	</metainfo>

	<AppLayout>
		<router-view />
	</AppLayout>
</template>

<script>
import AppLayout from '@/views/_layouts/AppLayout';
import { useMeta } from 'vue-meta';

export default {
	name: 'App',

	components: { AppLayout },

	data() {
		return {};
	},

	setup() {
		// const title = '數眠羊 Hybrid Sheep';
		const description = '依據科學與邏輯研發，嚴選用料謹慎堆疊。全程台灣在地生產製造，提供 60 天試睡與 15 年保固。';
		const image = 'https://d1hes8uqcp6qpi.cloudfront.net/img/fb-cover.png';

		// set meta
		const { meta } = useMeta({
			// title: title,

			description: description,

			og: {
				// title: title,
				description: description,
				image: image,
			},
		});

		return {
			meta,
		};
	},
};
</script>
