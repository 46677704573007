import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './styles/_index.css';
import pinia from './store/';

// sentry
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/tracing';

import option from './constant/_index';
// import Notifications from "@kyvg/vue3-notification";

// import InfiniteLoading from "v3-infinite-loading";
// import "v3-infinite-loading/lib/style.css"; //required if yo

const isProduction = process.env.NODE_ENV === 'production';

declare global {
	interface Window {
		isInitCart: boolean;
		isLogin: boolean;
		isAdmin: boolean;
		isStatusChecked: boolean;

		// tracking
		gtag: any;
		fbq: any;
	}
}

import { createMetaManager } from 'vue-meta';

const metaManager = createMetaManager();

const app = createApp(App);

/*
..######...#######..##.....##.########...#######..##....##.########.##....##.########
.##....##.##.....##.###...###.##.....##.##.....##.###...##.##.......###...##....##...
.##.......##.....##.####.####.##.....##.##.....##.####..##.##.......####..##....##...
.##.......##.....##.##.###.##.########..##.....##.##.##.##.######...##.##.##....##...
.##.......##.....##.##.....##.##........##.....##.##..####.##.......##..####....##...
.##....##.##.....##.##.....##.##........##.....##.##...###.##.......##...###....##...
..######...#######..##.....##.##.........#######..##....##.########.##....##....##...
*/
// app.component("infinite-loading", InfiniteLoading);

/*
.########.####.##.......########.########.########.
.##........##..##..........##....##.......##.....##
.##........##..##..........##....##.......##.....##
.######....##..##..........##....######...########.
.##........##..##..........##....##.......##...##..
.##........##..##..........##....##.......##....##.
.##.......####.########....##....########.##.....##
*/
app.config.globalProperties.getAssetUrl = function (q: string) {
	return process.env.ASSET_URL + q;
};

app.config.globalProperties.getDatetime = function (q: string) {
	try {
		return new Date(q).toLocaleString();
	} catch (e) {
		return q;
	}
};

app.config.globalProperties.typeText = function (key: string, id: number, defaultText = '--'): string {
	try {
		const temp = option[key].find((z: any) => z.id === id);
		if (temp) {
			defaultText = temp.name;
		}
	} catch (e) {
		// yada
	}
	return defaultText;
};

app.config.globalProperties.number = function (q: number) {
	const formatterNumber = new Intl.NumberFormat('zh-Hant-TW');
	return formatterNumber.format(q);
};

/*
.##.....##.########.##.......########..########.########.
.##.....##.##.......##.......##.....##.##.......##.....##
.##.....##.##.......##.......##.....##.##.......##.....##
.#########.######...##.......########..######...########.
.##.....##.##.......##.......##........##.......##...##..
.##.....##.##.......##.......##........##.......##....##.
.##.....##.########.########.##........########.##.....##
*/
app.config.globalProperties.isEmpty = function (q: string) {
	return q === '' || q === null;
};

app.config.globalProperties.option = option;

/*
....###....########.########.########..####.########..##.....##.########.########
...##.##......##.......##....##.....##..##..##.....##.##.....##....##....##......
..##...##.....##.......##....##.....##..##..##.....##.##.....##....##....##......
.##.....##....##.......##....########...##..########..##.....##....##....######..
.#########....##.......##....##...##....##..##.....##.##.....##....##....##......
.##.....##....##.......##....##....##...##..##.....##.##.....##....##....##......
.##.....##....##.......##....##.....##.####.########...#######.....##....########
*/
app.config.globalProperties.isProduction = isProduction;
app.config.globalProperties.isDev = !isProduction;

/*
..######..########.##....##.########.########..##....##
.##....##.##.......###...##....##....##.....##..##..##.
.##.......##.......####..##....##....##.....##...####..
..######..######...##.##.##....##....########.....##...
.......##.##.......##..####....##....##...##......##...
.##....##.##.......##...###....##....##....##.....##...
..######..########.##....##....##....##.....##....##...
*/
if (isProduction) {
	Sentry.init({
		app,
		environment: process.env.NODE_ENV,
		dsn: process.env.SENTRY_DSN,
		integrations: [
			new BrowserTracing({
				routingInstrumentation: Sentry.vueRouterInstrumentation(router),
				tracingOrigins: ['localhost', 'unipapa.studio', /^\//],
			}),
		],
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 1.0,
		// debug: true,
	});
}

app.use(pinia);
app.use(router);
app.use(metaManager);
// app.use(Notifications);

app.mount('#app');
