<template>
	<main>
		<!--
        .##.....##.########....###....########..########.########.
        .##.....##.##.........##.##...##.....##.##.......##.....##
        .##.....##.##........##...##..##.....##.##.......##.....##
        .#########.######...##.....##.##.....##.######...########.
        .##.....##.##.......#########.##.....##.##.......##...##..
        .##.....##.##.......##.....##.##.....##.##.......##....##.
        .##.....##.########.##.....##.########..########.##.....##
        -->
		<div
			class="sm: sm:h-18 fixed z-50 flex h-16 w-full items-center justify-center py-5 px-8"
			id="header"
			:class="{
				hide: isHideHeader && !isOpen,
			}"
			style="background: rgba(255, 255, 255, 1)"
		>
			<!-- portrait -->
			<img
				src="https://d1hes8uqcp6qpi.cloudfront.net/img/icon-31.svg"
				class="absolute left-8 h-6 sm:hidden"
				@click="isOpen = !isOpen"
				v-if="!isOpen"
			/>
			<img
				src="https://d1hes8uqcp6qpi.cloudfront.net/img/icon-cross.svg"
				class="absolute left-8 h-5 sm:hidden"
				@click="isOpen = !isOpen"
				v-else
			/>

			<!-- portrait -->
			<div class="absolute right-8 h-6 sm:hidden">
				<router-link to="/checkout/form">
					<div class="relative">
						<img src="https://d1hes8uqcp6qpi.cloudfront.net/img/icon-32.svg" class="h-6" />
						<div
							v-if="store.cart.length > 0"
							class="absolute h-5 w-5 rounded-full bg-black text-center text-white"
							style="right: -10px; top: -10px"
						>
							{{ store.cart.length }}
						</div>
					</div>
				</router-link>
			</div>

			<router-link to="/">
				<img src="https://d1hes8uqcp6qpi.cloudfront.net/img/logo2.svg" class="mr-0 h-6 md:mr-5 lg:mr-10" />
			</router-link>

			<router-link
				to="/product/modular-sheep"
				target="_blank"
				class="mr-3 hidden px-2 py-3 text-13 text-black sm:block"
				>組綿羊沙發</router-link
			>
			<router-link
				to="/product/item/99/黑眠羊床墊系列"
				target="_blank"
				class="mr-3 hidden px-2 py-3 text-13 text-black sm:block"
				>黑眠羊床墊</router-link
			>
			<router-link to="/product/hybrid-sheep" class="mr-3 hidden px-2 py-3 text-13 text-black sm:block"
				>數眠羊床墊</router-link
			>

			<router-link
				to="/product/item/101/%E6%A2%A3%E6%9C%A8%E5%BA%8A%E6%9E%B6"
				class="mr-3 hidden px-2 py-3 text-13 text-black sm:block"
				>實木床架</router-link
			>
			<router-link to="/product/listing" class="mr-3 hidden px-2 py-3 text-13 text-black sm:block"
				>全部商品</router-link
			>

			<div class="ml-auto hidden sm:flex sm:flex-row sm:items-center">
				<router-link to="/checkout/form">
					<div class="relative mr-5">
						<img src="https://d1hes8uqcp6qpi.cloudfront.net/img/icon-32.svg" class="h-6" />
						<div
							v-if="store.cart.length > 0"
							class="absolute h-5 w-5 rounded-full bg-black text-center text-white"
							style="right: -10px; top: -10px"
						>
							{{ store.cart.length }}
						</div>
					</div>
				</router-link>

				<router-link v-if="store.isLogin" to="/user/index" class="flex items-center">
					<p class="pl-2">{{ store.user.name }}</p>
				</router-link>

				<router-link v-else to="/login" class="ml-auto hidden sm:flex sm:flex-row sm:items-center">
					<p class="pl-2">登入</p>
				</router-link>
			</div>
		</div>

		<!-- drawer -->
		<!--
        .########..########.....###....##......##.########.########.
        .##.....##.##.....##...##.##...##..##..##.##.......##.....##
        .##.....##.##.....##..##...##..##..##..##.##.......##.....##
        .##.....##.########..##.....##.##..##..##.######...########.
        .##.....##.##...##...#########.##..##..##.##.......##...##..
        .##.....##.##....##..##.....##.##..##..##.##.......##....##.
        .########..##.....##.##.....##..###..###..########.##.....##
        -->
		<div class="fixed z-20 w-screen w-full overflow-auto bg-F4F5F6" id="drawer" :class="{ active: isOpen }">
			<div class="px-8">
				<div class="h-10"></div>

				<div class="text-12 text-grey">產品分類</div>
				<div class="h-3"></div>

				<router-link
					to="/product/modular-sheep"
					class="block rounded-8 border border-e3e3e3 bg-white py-3 text-center"
					@click="isOpen = false"
					>組綿羊沙發</router-link
				>
				<div class="h-3"></div>

				<router-link
					to="/product/item/99/黑眠羊床墊系列"
					class="block rounded-8 border border-e3e3e3 bg-white py-3 text-center"
					@click="isOpen = false"
					>黑眠羊床墊</router-link
				>
				<div class="h-3"></div>

				<router-link
					to="/"
					class="block rounded-8 border border-e3e3e3 bg-white py-3 text-center"
					@click="isOpen = false"
					>數眠羊床墊</router-link
				>
				<div class="h-3"></div>
				<router-link
					to="/product/item/101/%E6%A2%A3%E6%9C%A8%E5%BA%8A%E6%9E%B6"
					class="block rounded-8 border border-e3e3e3 bg-white py-3 text-center"
					@click="isOpen = false"
					>實木床架</router-link
				>
				<div class="h-3"></div>

				<router-link
					to="/product/listing"
					class="block rounded-8 border border-e3e3e3 bg-white py-3 text-center"
					@click="isOpen = false"
					>全部商品</router-link
				>
				<div class="h-3"></div>

				<a
					href="https://www.unipapa.com/"
					class="block rounded-8 border border-e3e3e3 bg-white py-3 text-center"
					target="_blank"
					>生活製品</a
				>

				<div class="h-5"></div>

				<div class="mb-3 text-12 text-grey">帳號資料</div>

				<router-link
					to="/user/index"
					class="mb-2 block rounded-full border border-e3e3e3 bg-white py-3 text-center"
					@click="isOpen = false"
					>帳號設定</router-link
				>
				<router-link
					to="/user/orders"
					class="mb-2 block rounded-full border border-e3e3e3 bg-white py-3 text-center"
					@click="isOpen = false"
					>訂單記錄</router-link
				>

				<button
					@click="
						logoutDo();
						isOpen = false;
					"
					class="block w-full rounded-full border border-e3e3e3 bg-white py-3 text-center text-blue"
				>
					登出
				</button>

				<div class="h-10"></div>

				<div class="flex items-center">
					<a href="https://www.facebook.com/unipapa" target="_blank">
						<img src="https://d1hes8uqcp6qpi.cloudfront.net/img/icon-fb-3.svg" class="mr-4 h-4" />
					</a>
					<a href="https://lin.ee/tQLdy2i" target="_blank">
						<img src="https://d1hes8uqcp6qpi.cloudfront.net/img/icon-line-3.svg" class="mr-4 h-4" />
					</a>
					<a href="https://www.instagram.com/unipapa_official/" target="_blank">
						<img src="https://d1hes8uqcp6qpi.cloudfront.net/img/icon-ig-2.svg" class="mr-4 h-4" />
					</a>
				</div>

				<hr class="my-5" />
				<div class="text-12">
					<div>落居百物股份有限公司</div>
					<div>© Sheeper Inc. All rights reserved.</div>
				</div>

				<div class="h-5"></div>
			</div>
		</div>

		<!--
		..######...#######..##....##.########.########.##....##.########
		.##....##.##.....##.###...##....##....##.......###...##....##...
		.##.......##.....##.####..##....##....##.......####..##....##...
		.##.......##.....##.##.##.##....##....######...##.##.##....##...
		.##.......##.....##.##..####....##....##.......##..####....##...
		.##....##.##.....##.##...###....##....##.......##...###....##...
		..######...#######..##....##....##....########.##....##....##...
		-->
		<div class="flex min-h-screen flex-col">
			<div class="h-[64px]"></div>

			<div class="flex-auto">
				<slot></slot>
			</div>

			<!-- footer -->
			<!--
			.########..#######...#######..########.########.########.
			.##.......##.....##.##.....##....##....##.......##.....##
			.##.......##.....##.##.....##....##....##.......##.....##
			.######...##.....##.##.....##....##....######...########.
			.##.......##.....##.##.....##....##....##.......##...##..
			.##.......##.....##.##.....##....##....##.......##....##.
			.##........#######...#######.....##....########.##.....##
			-->
			<div class="bg-grey py-10 text-white">
				<!-- portrait -->
				<div class="md:hidden">
					<div class="container"></div>
				</div>

				<!-- landscape -->
				<div class="   ">
					<div class="container">
						<div class="flex items-center justify-between leading-6">
							<div class="flex items-center">
								<img src="https://d1hes8uqcp6qpi.cloudfront.net/img/icon-35.svg" class="mr-2 h-4" />
								<div>
									<a href="mailto:hello@sheeper.com.tw"> hello@sheeper.com.tw </a>
								</div>
							</div>
							<div class="flex items-center">
								<a href="https://www.facebook.com/sheeperofficial/" target="_blank">
									<img
										src="https://d1hes8uqcp6qpi.cloudfront.net/img/icon-fb-2.svg"
										class="ml-4 h-4"
									/>
								</a>
								<a href="https://lin.ee/tQLdy2i" target="_blank">
									<img
										src="https://d1hes8uqcp6qpi.cloudfront.net/img/icon-line-2.svg"
										class="ml-4 h-4"
									/>
								</a>
								<a
									href="https://www.instagram.com/sheeper__official?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
									target="_blank"
								>
									<img src="https://d1hes8uqcp6qpi.cloudfront.net/img/icon-ig.svg" class="ml-4 h-4" />
								</a>
							</div>
						</div>
						<hr class="my-5 border-white" />

						<div class="flex flex-col leading-6 md:flex-row md:justify-between">
							<div class="text-left">
								<div>台灣台北市大安區敦化南路二段63巷53弄7號</div>
								<div>客服電話：<a href="tel:0227048669">02-2704-8669</a></div>
							</div>
							<div class="mt-10 md:mt-0 md:text-right">
								<div>
									<router-link to="/privacy">隱私權政策</router-link>｜
									<router-link to="/support">服務條款</router-link>
								</div>
								<div>© Sheeper Inc. All rights reserved.</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!--
		.##....##..#######..########.####.########.####..######.....###....########.####..#######..##....##
		.###...##.##.....##....##.....##..##........##..##....##...##.##......##.....##..##.....##.###...##
		.####..##.##.....##....##.....##..##........##..##........##...##.....##.....##..##.....##.####..##
		.##.##.##.##.....##....##.....##..######....##..##.......##.....##....##.....##..##.....##.##.##.##
		.##..####.##.....##....##.....##..##........##..##.......#########....##.....##..##.....##.##..####
		.##...###.##.....##....##.....##..##........##..##....##.##.....##....##.....##..##.....##.##...###
		.##....##..#######.....##....####.##.......####..######..##.....##....##....####..#######..##....##
		-->
		<div class="pointer-events-none fixed top-32 w-full lg:top-32">
			<div class="container text-right">
				<div class="mb-2" v-for="(x, i) of store.notifications.filter((z) => z.isActive)" :key="i">
					<div class="shadow-1 inline-block rounded-5 bg-blue py-1 px-3 text-white">
						{{ x.name }}
					</div>
				</div>
			</div>
		</div>

		<!--
		.##.....##..#######..########.....###....##......
		.###...###.##.....##.##.....##...##.##...##......
		.####.####.##.....##.##.....##..##...##..##......
		.##.###.##.##.....##.##.....##.##.....##.##......
		.##.....##.##.....##.##.....##.#########.##......
		.##.....##.##.....##.##.....##.##.....##.##......
		.##.....##..#######..########..##.....##.########
		-->
		<div
			class="fixed left-0 top-0 z-[99] h-screen w-screen"
			style="background: rgba(0, 0, 0, 0.6)"
			v-if="isModal.yy"
			@click="isModal.yy = false"
		>
			<div class="flex h-full w-full items-center">
				<div class="relative mx-auto max-w-[600px] px-5" @click.stop>
					<img src="https://d1hes8uqcp6qpi.cloudfront.net/img/icon-43.png" class="w-full" />
					<div class="absolute" style="right: 5%; top: -50px" @click="isModal.yy = false">
						<img src="https://d1hes8uqcp6qpi.cloudfront.net/img/icon-41.png" class="pointer w-10" />
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import { useStore } from '../../store/main';

const isProduction = process.env.NODE_ENV === 'production';

export default {
	setup() {
		const store = useStore();
		return {
			store,
		};
	},

	data() {
		return {
			isProduction,
			isOpen: false,
			valScrollDown: 0,
			valScrollUp: 0,
			countScrollDown: 0,
			countScrollUp: 0,
			lastKnownScrollPosition: 0,
			isHideHeader: false,
			isModal: {
				yy: false,
			},
		};
	},

	mounted() {
		document.addEventListener('scroll', this.onScroll);
		if (this.isDev) {
			this.isModal.yy = false;
		}
	},

	unmounted() {
		document.removeEventListener('scroll', this.onScroll);
	},

	methods: {
		logoutDo() {
			this.store.logout();
			this.$router.push('/');
		},

		onScroll() {
			const self = this;

			const scrollY = window.scrollY;

			if (scrollY >= self.lastKnownScrollPosition) {
				// down --------------------------------------------
				self.countScrollDown++;
				self.countScrollUp = 0;

				const v = scrollY - self.lastKnownScrollPosition;
				self.valScrollDown += v;
				self.valScrollUp -= v;

				if (self.valScrollUp < 0) {
					self.valScrollUp = 0;
				}

				if (self.valScrollDown > 200) {
					self.valScrollDown = 200;
				}
			} else {
				// up --------------------------------------------
				self.countScrollUp++;
				self.countScrollDown = 0;

				const v = self.lastKnownScrollPosition - scrollY;

				self.valScrollDown -= v;
				self.valScrollUp += v;

				if (self.valScrollDown < 0) {
					self.valScrollDown = 0;
				}

				if (self.valScrollUp > 200) {
					self.valScrollUp = 200;
				}
			}

			self.lastKnownScrollPosition = window.scrollY;

			let isShowHeader = false;

			if (self.valScrollUp > 100) {
				isShowHeader = true;
			}

			if (self.lastKnownScrollPosition < 100) {
				isShowHeader = true;
			}
			self.isHideHeader = !isShowHeader;
		},
	},
};
</script>
