const items: Array<any> = [
	{ id: 1, name: '數眠羊床墊' },
	// { id: 3, name: "模組沙發" },
	{ id: 4, name: '實木床架' },
	// { id: 5, name: "Unipapa" }, // removed by chiyu
	{ id: 6, name: '寢具用品' },
	{ id: 7, name: '黑眠羊' },
];

export default items;
