function removeFromCart(q: number) {
	window.gtag('event', 'remove_from_cart', {
		items: [{ id: q }],
	});
}

function beginCheckout(items: any, couponCode: string) {
	window.gtag('event', 'begin_checkout', {
		items,
		coupon: couponCode,
	});

	window.fbq('track', 'InitiateCheckout');
}

function addToCart(id: number, price: number) {
	window.gtag('event', 'add_to_cart', { items: [{ id: id }] });

	window.fbq('track', 'AddToCart', {
		content_type: 'product',
		content_ids: id,
		value: price,
		currency: 'TWD',
	});
}

function viewItem(id: number, price: number) {
	// gtag
	window.gtag('event', 'view_item', {
		items: [{ id: id }],
	});

	// fb
	window.fbq('track', 'ViewContent', {
		content_type: 'product',
		content_ids: id,
		value: price,
		currency: 'TWD',
	});
}

function purchase(order: any, orderProducts: [any]) {
	window.gtag('event', 'purchase', {
		transaction_id: order.code,
		value: order.price,
		shipping: order.priceDeliver,
		coupon: order.couponCode,
		items: orderProducts.map((x) => ({
			id: x.productID,
			name: x.productName,
			price: x.productPrice,
			quantity: x.quantity,
		})),
	});

	window.fbq('track', 'Purchase', {
		value: order.price,
		currency: 'TWD',
		content_type: 'product',
		contents: orderProducts.map((z) => ({
			id: z.productID,
			item_price: z.productPrice,
			quantity: z.quantity,
		})),
	});
}

export default { removeFromCart, viewItem, addToCart, beginCheckout, purchase };
