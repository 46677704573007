import area from './area';
import city from './city';
import gender from './gender';
import is from './is';
import pageSize from './pageSize';
import orderStatus from './orderStatus';
import period from './period';
import contactType from './contactType';
import couponType from './couponType';
import productType from './productType';
import productRedirect from './productRedirect';

const CONSTANTS: any = {
	area,
	city,
	gender,
	is,
	pageSize,
	orderStatus,
	period,
	contactType,
	couponType,
	productType,
	productRedirect,
};

export default CONSTANTS;

export { area, city, gender, is, pageSize, orderStatus, period, contactType, couponType, productType, productRedirect };
