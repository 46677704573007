const items: Array<any> = [
	{ id: 1, name: '填單中' },
	{ id: 2, name: '已送表單' },
	{ id: 3, name: '中租處理中' },
	{ id: 4, name: '訂購品項' },
	{ id: 5, name: '等待出貨' },
	{ id: 6, name: '問題件' },
];

export default items;
