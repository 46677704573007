const items: Array<any> = [
	// 床框版
	// 床頭版
	{ id: 82, name: 'bedstead' },
	{ id: 81, name: 'bedstead' },
	{ id: 80, name: 'bedstead' },
	{ id: 79, name: 'bedstead' },
	{ id: 94, name: 'bedstead' },

	// 數眠羊
	{ id: 66, name: 'hybrid-sheep' },
	{ id: 67, name: 'hybrid-sheep' },
	{ id: 68, name: 'hybrid-sheep' },
	{ id: 69, name: 'hybrid-sheep' },
	{ id: 70, name: 'hybrid-sheep' },
	{ id: 71, name: 'hybrid-sheep' },
	{ id: 72, name: 'hybrid-sheep' },
	{ id: 73, name: 'hybrid-sheep' },
	{ id: 74, name: 'hybrid-sheep' },
	{ id: 75, name: 'hybrid-sheep' },
	{ id: 76, name: 'hybrid-sheep' },
	{ id: 77, name: 'hybrid-sheep' },
	{ id: 78, name: 'hybrid-sheep' },
];

export default items;
