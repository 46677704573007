const items: Array<any> = [
	{
		id: 1,
		name: '男',
	},
	{
		id: 2,
		name: '女',
	},
];

export default items;
